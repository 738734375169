@import "./colors.sass";
@import "./variables.sass";

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.top-shadow {
  -webkit-box-shadow: 2px -6px 16px -12px rgba(82, 81, 82, 0.62);
  -moz-box-shadow: 2px -6px 16px -12px rgba(82, 81, 82, 0.62);
  box-shadow: 2px -6px 16px -12px rgba(82, 81, 82, 0.62); }

.margin-top-15 {
  margin-top: 15px !important; }

.margin-right-15 {
  margin-right: 15px !important; }

.pointer {
  cursor: pointer; }

.with-hover-grey {
  &:hover {
    background-color: rgba(0, 0, 0, .1); } }
