.text-center {
  text-align: center; }

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.truncate-3-lines {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

.fade-text-3-lines {
  position: relative;
  max-height: 3.6em;
  overflow: hidden;

  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 27%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%); } }

.capitalized {
  text-transform: capitalize; }

.uppercase {
  text-transform: uppercase; }

.wight-700 {
  font-weight: 700; }

.font-bold {
  font-family: SourceSansPro-Bold, sans-serif !important; }

html:lang(hy) {
  .font-bold {
    font-family: FedraSansArm-Bold, sans-serif !important; } }

.no-text-decoration {
  text-decoration: none; }
