.pad-10 {
  padding: 0.625rem; }

.pad-20 {
  padding: 1.25rem; }

.pad-15 {
  padding: 0.9375rem; }

.pad-top-15 {
  padding-top: 0.9375rem; }

.pad-top-20 {
  padding-top: 1.25rem; }

.pad-top-30 {
  padding-top: 1.875rem; }

.pad-bottom-15 {
  padding-bottom: 0.9375rem; }

.pad-bottom-20 {
  padding-bottom: 1.25rem; }

.pad-bottom-30 {
  padding-bottom: 1.875rem; }

.pad-sides-20 {
  padding: 0 1.25rem; }

.pad-sides-15 {
  padding: 0 0.9375rem; }

.pad-sides-10 {
  padding: 0 0.625rem; }

.pad-sides-5 {
  padding: 0 5px; }

.mrg-15 {
  margin: 0.9375rem; }

.mrg-top-15 {
  margin-top: 0.9375rem; }

.mrg-sides-15 {
  margin: 0 0.9375rem; }

.mrg-sides-10 {
  margin: 0 0.625rem; }

.mrg-sides-5 {
  margin: 0 5px; }
